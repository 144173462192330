
.container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

:root {
    /* //....... Color ........// */
    --primary-color: #ff3c78;
    --light-black: #B7C4CF;
    --black: #000;
    --white: #fff;
    --grey: #aaa;
}

.contact {
    margin-top: 45px;
    font-family: 'Roboto', sans-serif;

}

.form {
    display: flex;
    justify-content: space-between;
    margin: 80px 0;
}

.form .form-txt {
    flex-basis: 48%;
}

.form .form-txt h1 {
    font-weight: 600;
    color: var(--black);
    font-size: 40px;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
    color: var(--light-black);
}

.form .form-txt span {
    color: var(--light-black);
    font-size: 14px;
}

.form .form-txt h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 15px 0;
    color: var(--light-black);
}

.form .form-txt p {
    color: var(--light-black);
    font-size: 14px;
}

.form .form-details {
    flex-basis: 48%;
}

.form .form-details input[type="text"],
.form .form-details input[type="email"] {
    padding: 15px 20px;
    color: var(--black);
    outline: none;
    border: 1px solid var(--grey);
    margin: 35px 15px;
    font-size: 14px;
}

.form .form-details textarea {
    padding: 15px 20px;
    margin: 0 15px;
    color: var(--black);
    outline: none;
    border: 1px solid var(--grey);
    font-size: 14px;
    resize: none;
}

.form .form-details button {
    padding: 15px 25px;
    color: var(--white);
    font-weight: 500;
    background: var(--black);
    outline: none;
    border: none;
    margin: 15px;
    font-size: 14px;
    letter-spacing: 2px;
    cursor: pointer;
}

@media (max-width: 500px) {
    .form {
        display: flex;
        flex-direction: column;
    }
    .form .form-details button {
        margin-left: 0;
    }
    .form .form-details input[type="text"],
    .form .form-details input[type="email"],
    .form .form-details textarea {
        width: 100%;
        margin-left: 0;
    }
    .form .form-details input[type="text"] {
        margin-bottom: 0px;
    }
}

@media(min-width: 501px) and (max-width: 768px) {
    .form {
        display: flex;
        flex-direction: column;
    }
    .form .form-details button {
        margin-left: 0;
    }
    .form .form-details input[type="text"],
    .form .form-details input[type="email"],
    .form .form-details textarea {
        width: 100%;
        margin-left: 0;
    }
    .form .form-details input[type="text"] {
        margin-bottom: 0px;
    }
}
.details{
    margin-top: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    background-color: #393053;
}
.icon{
    margin-right: 0.8rem;
}