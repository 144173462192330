.vishesh__header {
  display: flex;
  flex-direction: row;
  margin-top: -2rem;
}

.vishesh__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 3.5rem;
  margin-top: 10rem;
}

.vishesh__header-content h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.02em;
  color: #B7C4CF;
}

.vishesh__header-content p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #B7C4CF;

  margin-top: 1.5rem;
}



.vishesh__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vishesh__header-image img {
  width: 100%;
  height: 100%;
  z-index: 1000;
}

@media screen and (max-width: 1050px) {
  .vishesh__header {
    margin-top: 15rem;
    margin-left: 1.5rem;

    flex-direction: column;
  }

  .vishesh__header-content {
    margin: 0 0 3rem;
  }
  .vishesh__navbar-links_logo img{
    margin-left: -0.5rem;
  }
}

@media screen and (max-width: 650px) {
  .vishesh__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .vishesh__header-content p {
    font-size: 16px;
    line-height: 24px;
  }

}
.vishesh__header-content__input {
  width: 100%;
  margin: 3rem 0 1rem;

  display: flex;
  flex-direction: row;
}
.vishesh__header-content__input button {
  flex: 0.2 0.2;
  width: 100%;
  min-height: 50px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #C1AEFC;
  border:  solid #C1AEFC;
  padding: 0 1rem;
  color: #140523;
  cursor: pointer;
  outline: none;

}
.vishesh__header-content__input button:hover{
  background: #18122B;
  color: #C1AEFC;
}

.vishesh__header-content__input p{
  color: #C1AEFC;
  margin-left: 10px;
  text-decoration: underline;
  margin-top: 2px;
}

@media screen and (max-width: 490px) {
  .vishesh__header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .vishesh__header-content p {
    font-size: 14px;
    line-height: 24px;
    padding: 0.2rem 0.2rem;
  }
  .vishesh__header-content__input{
    margin: 1.5rem 0px 1.5rem;
  }
  .Vishesh__circle a{
    font-size: 22px !important;
   
  }
  .vishesh__header-content__input button{
    font-size: 14px;
    flex: 0.4 0.4;
  }
  .vishesh__header-image{
    margin-left: -3rem;
    margin-top: -3rem;
  }


}

.Vishesh__rectangle {
  background-color: #C1AEFC;
  border-radius: 8px;
  margin-top: -14rem;
  margin-bottom: 1rem;

}

.Vishesh__rectangle h3 {
  color: #18122B;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 0.5rem;

}


.Vishesh__circle{
  padding: 1rem 0rem;
  
}
.Vishesh__circle a{
  font-size: 26px;
  padding: 1rem 0.5rem;
  color: #B7C4CF;
}
