
.heading {
    flex-basis: 100%;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 75px;
    letter-spacing: -0.02em;
    color: #B7C4CF;
    text-align: center;
    margin-top: -2rem;
    margin-bottom: 2rem;
}
.exp{
    min-width: 320px;
    padding: 2rem 3rem;
}

 .timeline {
    list-style: none;
    padding: 10px 0;
    
    position: relative;
    font-weight: 300;
}
.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content:" ";
    width: 2px;
    background: #ffffff;
    left: 50%;
    margin-left: -1.5px;
}
.timeline > li {
    margin-bottom: 20px;
    position: relative;
    width: 50%;
    float: left;
    clear: left;
}
.one{
    margin-top: 0rem;
}
.two{
    margin-top: 12rem !important;
}
.three{
    margin-top: 5rem;

}
.four{
    margin-top: 4rem !important;
}
.five{
    margin-top: 10rem;
}
.six{
    margin-top: 5rem;
}

.timeline > li:before, .timeline > li:after {
    content:" ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li:before, .timeline > li:after {
    content:" ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li > .timeline-panel {
    width: calc(100% - 25px);
    width: -moz-calc(100% - 25px);
    width: -webkit-calc(100% - 25px);
    float: left;
    background: #B7C4CF;
    position: relative;
    padding: 2rem 2rem;
    border-radius: 6px;
}
.timeline > li > .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #dcdcdc;
    border-right: 0 solid #dcdcdc;
    border-bottom: 15px solid transparent;
    content:" ";
}
.timeline > li > .timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #B7C4CF;
    border-right: 0 solid #B7C4CF;
    border-bottom: 14px solid transparent;
    content:" ";
}
.timeline > li > .timeline-badge {
    color: #ffffff;
    width: 24px;
    height: 24px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 16px;
    right: -12px;
    z-index: 100;
}
.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}
.timeline-badge > a {
    color: #ffffff !important;
}
.timeline-badge a:hover {
    color: #dcdcdc !important;
}
.timeline-title {
    margin-top: 0;
    color: inherit;
}
.timeline-heading h4 {
    padding: 0 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #18122B;
}
.timeline-body > p, .timeline-body > ul {
    padding: 10px 15px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    color: #696969;

}
.timeline-body > p, .timeline-body > ul >li{
    padding: 0.4rem 0.2rem;
}
.timeline-footer {
    padding: 5px 15px;
    font-family: 'Roboto', sans-serif;

}
.timeline-footer p { margin-bottom: 0; }
.timeline-footer > a {
    cursor: pointer;
    text-decoration: none;
}
.timeline > li.timeline-inverted {
    float: right;
    clear: right;
}
.timeline > li:nth-child(2) {
    margin-top: 60px;
}
.timeline > li.timeline-inverted > .timeline-badge {
    left: -12px;
}
.no-float {
    float: none !important;
}
@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline > li {
        margin-bottom: 0px;
        position: relative;
        width:100%;
        float: left;
        clear: left;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(100% - 65px);
        width: -moz-calc(100% - 65px);
        width: -webkit-calc(100% - 65px);
    }
    ul.timeline > li > .timeline-badge {
        left: 28px;
        margin-left: 0;
        top: 16px;
    }
    ul.timeline > li > .timeline-panel {
        float: right;
    }
    ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }
    ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
    .timeline > li.timeline-inverted {
        float: left;
        clear: left;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .timeline > li.timeline-inverted > .timeline-badge {
        left: 28px;
    }
}
@media screen and (max-width: 490px) {
    
    .exp{
        padding: 2rem 1rem;
        margin-left:-2.5rem;
    }
    .one{
        margin-top: 0rem;
    }
    .two{
        margin-top: 2rem !important;
    }
    .three{
        margin-top: 0rem;
    
    }
    .four{
        margin-top: 2rem !important;
    }
    .five{
        margin-top: 0rem;
    }
    .six{
        margin-top: 4rem;
    }
    .heading{
       margin-bottom: 0.2rem;
    }
  }