* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}


  a {
    color: unset;
    text-decoration: none;
  }


  .gradient__bg {

  /* ff 3.6+ */
  background:-moz-linear-gradient(180deg, rgba(39, 15, 54, 1) 6%, rgba(100, 43, 107, 1) 50%, rgba(200, 107, 152, 1) 94%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(180deg, rgba(39, 15, 54, 1) 6%, rgba(100, 43, 107, 1) 50%, rgba(200, 107, 152, 1) 94%);

  /* opera 11.10+ */
  background:-o-linear-gradient(180deg, rgba(39, 15, 54, 1) 6%, rgba(100, 43, 107, 1) 50%, rgba(200, 107, 152, 1) 94%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C86B98', endColorstr='#270F36', GradientType=1 );

  /* ie 10+ */
  background:-ms-linear-gradient(180deg, rgba(39, 15, 54, 1) 6%, rgba(100, 43, 107, 1) 50%, rgba(200, 107, 152, 1) 94%);

  /* global 94%+ browsers support */
  background:linear-gradient(180deg, rgba(39, 15, 54, 1) 6%, rgba(100, 43, 107, 1) 50%, rgba(200, 107, 152, 1) 94%);

}
        

html {
  background: radial-gradient(ellipse at bottom, #1b1727 0%, #18122B 100%);
}
