.navbar {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 50px;
}

.navbar-logo {
  color: #000000;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}


@media screen and (max-width: 960px) {
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 30px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100
  }
}

.vishesh__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 1rem 3rem;
  margin-left: -70px;
}

.vishesh__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}


.vishesh__navbar-links_content a {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: #B7C4CF;

}

.vishesh__navbar-links_content a:hover {
  color: #635985;

}

.vishesh__navbar-links_logo img {
  max-width: 70%;

  margin-top: -1rem;

}

.vishesh__navbar-links_content {
  display: flex;
  flex-direction: row;
  margin-left: 26rem;
}

.vishesh__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.vishesh__navbar-links_content p,
.vishesh__navbar-sign p,
.vishesh__navbar-menu_content p {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.vishesh__navbar-sign button,
.vishesh__navbar-menu_content button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #865DFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.vishesh__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.vishesh__navbar-menu svg {
  cursor: pointer;
}

.vishesh__navbar-menu_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 1;
  text-align: end;
  background: #B7C4CF;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.vishesh__navbar-menu_content p {
  margin: 1rem 0;
}

.vishesh__navbar-menu_content-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .vishesh__navbar-links_content {
    display: none;
  }

  .vishesh__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .vishesh__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .vishesh__navbar {
    padding: 2rem;
  }

  .vishesh__navbar-sign {
    display: none;
  }

  .vishesh__navbar-menu_content {
    top: 20px;
  }

  .vishesh__navbar-menu_content-links-sign {
    display: block;
  }

  .vishesh__navbar-menu_content p {
    color: #18122B;
  }
}