
.wrap{
	width: 100%;
    background-color: #B7C4CF;
	max-width: 1400px;
	height: 100%;
	margin: 0 auto;
	padding-left: 5px;
	padding-right: 5px;
    font-family: 'Roboto', sans-serif;
    padding-top: 1rem;
    margin-top: -4.4rem;    
}
.padding{
	padding: 2%;
}

/** Layout **/
.intro-details{
	display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
}
	.intro-details > .intro-image{
	    width: 24%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.intro-details > .intro-paragraph{
		width: 76%;
	}

.about-info{
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
}
	.about-info > .profile{
		-webkit-flex: 1 0 0;
   		flex: 1 0 0;
	}
	.about-info > .skills{
		-webkit-flex: 1 0 0;
   		flex: 1 0 0;
	}

.about-buttons{
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	margin: 0 auto;
}
	.about-buttons div{
		width: 100%;
	}

/** Elements **/
.intro-image > .image{
	max-width: 60%;
	display: block;
	margin: 0 auto;
}
	.intro-image > .image > .image-circle{
		width: 100%;
		border-radius: 50%;
		margin: 0 auto;
		border: 5px solid #252439;
		transition: all .2s ease-in;
		-webkit-transition: all .2s ease-in;
		-moz-transition: all .2s ease-in;
		-o-transition: all .2s ease-in;
	}

.about-button{
	min-width: 70%;
	height: 100%;
	padding: 20px;
    font-family: 'Roboto', sans-serif;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: .3rem;
	cursor: pointer;
	font-size: 14px;
	transition: all .3s ease-in;
	-webkit-transition: all .3s ease-in;
	-moz-transition: all .3s ease-in;
	-o-transition: all .3s ease-in;
}
	.hire-me > .about-button{
		margin-right: 25px;
		float: right;
		color: #252439;
    	background-color: transparent;
		border: 3px solid #252439;
	}
		.hire-me > .about-button:hover{
			color: #ffffff;
			background-color: #18122B;
		}
	.download-cv > .about-button{
		margin-left: 25px;
		color: #fff;
    	background-color: #18122B;
		border: none;
		float: left;
	}
		.download-cv > .about-button:hover{
			background-color: #000;
		}
.about-button {
	background-color: #18122B;
	color: #f7f7f7;

}
.skill-bars {
	list-style: none;
	margin: 6rem 0 3rem;
	padding: 0px;
}
.skill-bars li {
	height: .3rem;
	background: #a1a1a1;
	width: 100%;
	margin-bottom: 4rem;
	padding: 0;
	position: relative;
}
.skill-bars li strong {
	position: absolute;
	left: 0;
	top: -1rem;
    font-family: 'Roboto', sans-serif;
	color: #252439;
	text-transform: uppercase;
	letter-spacing: .115rem;
	font-weight: normal;
	font-size: 16px;
	line-height: 0;
}
.skill-bars li .progress {
	background: #252439;
	position: relative;
	height: 100%;
}
.skill-bars li .progress span {
	position: absolute;
	right: 0;
	top: -3rem;
	display: block;
    font-family: 'Roboto', sans-serif;
	color: white;
	font-size: 14px;
	line-height: 1;
	background: #252439;
	padding: .6rem .6rem;
	border-radius: 3px;
}
.skill-bars li .progress span::after {
	position: absolute;
	left: 50%;
	bottom: -5px;
	margin-left: -5px;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	border-top: 5px solid #313131;
	content: "";
}

.skill-bars li .percent5   { width: 5%; }
.skill-bars li .percent10  { width: 10%; }
.skill-bars li .percent15  { width: 15%; }
.skill-bars li .percent20  { width: 20%; }
.skill-bars li .percent25  { width: 25%; }
.skill-bars li .percent30  { width: 30%; }
.skill-bars li .percent35  { width: 35%; }
.skill-bars li .percent40  { width: 40%; }
.skill-bars li .percent45  { width: 45%; }
.skill-bars li .percent50  { width: 50%; }
.skill-bars li .percent55  { width: 55%; }
.skill-bars li .percent60  { width: 60%; }
.skill-bars li .percent65  { width: 65%; }
.skill-bars li .percent70  { width: 70%; }
.skill-bars li .percent75  { width: 75%; }
.skill-bars li .percent80  { width: 80%; }
.skill-bars li .percent85  { width: 85%; }
.skill-bars li .percent90  { width: 90%; }
.skill-bars li .percent95  { width: 95%; }
.skill-bars li .percent100 { width: 100%; }



/** Styles **/
.about h1, .about h2, .about h3, .about h4{
	color: #252439;
    font-family: 'Roboto', sans-serif;
}
.about h1, .about h4{
    font-family: 'Roboto', sans-serif;
	text-align: center;
    color: #18122B;
}
.about h1{
	margin-top: 0px;
}
.about h4{
	color: #18122B;
	letter-spacing: .25rem;
    margin-top: 1rem;
	margin-bottom: 8px;
	text-transform: uppercase;
}
.about h2{
	text-transform: uppercase;
	letter-spacing: .25rem;
	font-size: 19px;
}
.bold{
	font-weight: 600;
	color: #18122B;
}
.about h3{
	margin-top: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
    letter-spacing: .25rem;
    font-size: 19px;
}
.about p{
	font-weight: 500;
    color: #696969;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    font-size: 16px;
	margin-top: 0.6rem;
}
.intro-paragraph p{
	line-height: 1.8 !important;
	font-size: 20px;
}


/** Media Queries **/
@media all and (max-width : 800px) {
	.about-info{
		flex-direction: column;
	}
	.about-info h2{
		text-align: center;
	}
	.about-buttons{
		flex-direction: column;
	}
	.about-button{
		width: 100%;
	}
		.hire-me > .about-button{
			float: none;
			margin-right: 0px;
			margin-bottom: 15px;
		}
		.download-cv > .about-button{
			margin-left: 0px;	
			float: none;
		}
	.intro-image > .image {
		max-width: 95%;	
	}
	.intro-paragraph p{
		line-height: 1.5 !important;
		font-size: 16px;
		font-weight: 400;
	}
	.about h1 {
		font-size: 22px
	}
	.about h2, .about h3, .about h4{
		font-size: 15px
	}
}
@media all and (max-width : 500px) {
	.intro-details > .intro-image{
	    width: 100%;
	}
	.intro-details > .intro-paragraph{
		width: 100%;
		text-align: center;
	}
	.intro-image > .image{
		width: 40%;
	}
	.wrap{
		margin-top: -2.6rem;
	}
	.about p{
		font-weight: 400;
	}
	.skills ,.profile{
		margin-top: 2rem;
	}
}
